import { Pipe, PipeTransform } from '@angular/core';
import { AccountStateEnum } from '../enums/account-state.enum';

@Pipe({
  name: 'accountStatusTranslateKey',
})
export class TranslateAccountStatusPipe implements PipeTransform {
  transform(status: AccountStateEnum): string {
    let translateKey: string = status;
    switch (status) {
      case AccountStateEnum.Active:
        translateKey = 'SHARED.ACCOUNT_STATUS.ACTIVE';
        break;
      case AccountStateEnum.Pending:
        translateKey = 'SHARED.ACCOUNT_STATUS.PENDING';
        break;
      case AccountStateEnum.Review:
        translateKey = 'SHARED.ACCOUNT_STATUS.REVIEW';
        break;
      case AccountStateEnum.Suspended:
        translateKey = 'SHARED.ACCOUNT_STATUS.SUSPENDED';
        break;
      case AccountStateEnum.Approved:
        translateKey = 'SHARED.ACCOUNT_STATUS.APPROVED';
        break;
      case AccountStateEnum.Denied:
        translateKey = 'SHARED.ACCOUNT_STATUS.DENIED';
        break;
      case AccountStateEnum.Error:
        translateKey = 'SHARED.ACCOUNT_STATUS.ERROR';
        break;
    }

    return translateKey;
  }
}
