import { MjxTableDataSource } from 'src/app/shared/modules/mjx-table/mjx-table-datasource';
import {AccountModel, OperationMetric, OperationSummary, OperationSummaryItems} from "../../../models/account.model";

const defaultNumber = '0';

export class AccountsStateModel {
  isLoading: boolean;
  currentAccount: AccountModel
  accounts: AccountModel[];
  filteredAccounts: AccountModel[];
  linkedAccounts: MjxTableDataSource<AccountModel>;
  userName: string;
  userType: string;
  isSavingTransfer: boolean;
  isEmpty: boolean;
  summary: string;
  isLoadingSummary: boolean;
  isLoadingLinkedAccounts: boolean;
  credits: OperationSummary;
  creditsTable: MjxTableDataSource<OperationSummaryItems>
  debitsTable: MjxTableDataSource<OperationSummaryItems>
  transactionAccountTable: MjxTableDataSource<any>
  proprietaryAccountTable: MjxTableDataSource<any>
  debits: OperationSummary;
  convertedCount: string;
  executedCount: string;
  creditCount: string;
  debitCount: string;
  totalCount: string;
  metrics: OperationMetric;
  creditSum: string;
  debitSum: string;
  isLoadingTransactionsSummary: boolean;
  isLoadingBalance: boolean;
  chartAccountOptions: any;
  hasError: boolean;
  partnerBrands: any[];
  accountBalance: any;
  isLoadingProperty: boolean;
  accountBalanceDate: any;
  isLoadingBalanceFilter: boolean
}

export const defaultAccountState: AccountsStateModel = {
  isLoading: true,
  isEmpty: false,
  isSavingTransfer: false,
  currentAccount: null,
  accounts: null,
  filteredAccounts: null,
  linkedAccounts: null,
  isLoadingLinkedAccounts: false,
  userName: null,
  userType: null,
  summary: defaultNumber,
  isLoadingSummary: false,
  credits: null,
  creditsTable: undefined,
  debitsTable: undefined,
  transactionAccountTable: undefined,
  proprietaryAccountTable: undefined,
  debits: null,
  metrics: null,
  creditSum: defaultNumber,
  debitSum: defaultNumber,
  convertedCount: defaultNumber,
  executedCount: defaultNumber,
  totalCount: defaultNumber,
  creditCount: defaultNumber,
  debitCount: defaultNumber,
  isLoadingTransactionsSummary: false,
  isLoadingBalance: false,
  chartAccountOptions: {},
  hasError: false,
  partnerBrands: undefined,
  accountBalance: null,
  isLoadingProperty: false,
  accountBalanceDate: null,
  isLoadingBalanceFilter: false
}
