import {RequestTransactionFilterModel} from "./request-transaction-filter.model";
import {TransactionsTypesEnum} from "../../../../shared/enums/transactions-types.enum";
import {RequestDataFilterModel} from "../../../../shared/models/request-filter.model";

export class GetPartnersTransactions {
  static readonly type = '[Transactions-ip] Get Partners Transactions';
  constructor(
    public filter: RequestDataFilterModel<RequestTransactionFilterModel>,
    public accountId: string,
  ) { }
}

export class GetCorporateTransactions {
  static readonly type = '[Transactions-ip] Get Corporate Transactions';
  constructor(
    public type: TransactionsTypesEnum,
    public filter: RequestDataFilterModel<RequestTransactionFilterModel>,
    public accountId?: string,
    public updateHeader?: boolean,
    public ignoreTransactionType?: boolean
  ) { }
}

export class GetCorporateTransactionByIdList {
  static readonly type = '[Transactions-ip] Get Corporate Transaction By Id List';
  constructor(public transactionId: string) { }
}

export class GetPartnersTransactionByIdList {
  static readonly type = '[Transactions-ip] Get Partners Transaction By Id List';
  constructor(public transactionId: string) { }
}

export class InvokeWebhook {
  static readonly type = '[Transactions-ip] Invoke Webhook';
  constructor(
    public accountId: string,
    public transactionId: string
  ) { }
}

export class SyncTransaction {
  static readonly type = '[Transactions-ip] Sync';
  constructor(
    public transactionId: string
  ) { }
}

export class InvokeCorporateWebhook {
  static readonly type = '[Transactions-ip] Invoke Corporate Webhook';
  constructor(
    public transactionId: string
  ) { }
}

// Todo verificar duplicidade, remover
export class PayTransaction {
  static readonly type = '[Transactions-ip] Pay';
  constructor(
    public transactionId: string,
    public filter: RequestDataFilterModel<RequestTransactionFilterModel>
  ) { }
}

export class GetTransactionLogs {
  static readonly type = '[Transactions-ip] Get Logs';
  constructor(
    public transactionId: string
  ) { }
}

export class DeleteTransactionLog {
  static readonly type = '[Transactions-ip] Delete Logs';
  constructor(
    public logId: string
  ) { }
}

