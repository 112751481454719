import { NavigationOption } from '../models/navigation-option.model';
import { Roles } from './roles-list';
import { CompanyUtils } from './company-utils';
import { Permission, PermissionEntity } from '../../core/models/permission.model';
import { isPartner } from './get-context';


const entitiesOptions: NavigationOption[] = [
  {
    title: 'NAVBAR.OPTIONS.USERS.TITLE',
    icon: 'account_circle',
    routerLink: 'users/list',

    permissionEntity: PermissionEntity.USERS,
    permission: Permission.List,
  },

  !isPartner && {
    title: 'NAVBAR.OPTIONS.USERS.PROFILES',
    icon: 'description',
    routerLink: 'users/profile-permissions',

    permissionEntity: PermissionEntity.USER_ROLES,
    permission: Permission.List,
  },
  {
    title: 'NAVBAR.OPTIONS.PARTNERS',
    icon: 'apartment',
    routerLink: 'partners',

    permissionEntity: PermissionEntity.CUSTOMERS,
    permission: Permission.List,
  },
  // {
  //   title: 'NAVBAR.OPTIONS.MERCHANTS',
  //   icon: 'store',
  //   routerLink: 'licenses',
  //   roles: Roles.MENU_ROUTES.MERCHANTS,

  //   permissionEntity: PermissionEntity.LICENSES,
  //   permission: Permission.List,
  // },
  {
    title: 'NAVBAR.OPTIONS.ACCOUNTS',
    icon: 'wallet',
    routerLink: 'accounts',

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.GetOverview,
  },
  {
    title: 'NAVBAR.OPTIONS.BANKS.OPERATION_BANKS',
    icon: 'account_balance',
    routerLink: 'operation-banks',

    permissionEntity: PermissionEntity.SYSTEM_CONFIG,
    permission: Permission.GetBanks,
  },
  {
    title: 'NAVBAR.OPTIONS.CNAES',
    icon: 'location_city',
    routerLink: 'cnaes',
    // roles: Roles.MENU_ROUTES.CNAES,

    permissionEntity: PermissionEntity.SYSTEM_CONFIG,
    permission: Permission.GetCNAEs,
  },
  // !isPartner &&{
  //   title: 'NAVBAR.OPTIONS.RECEIVERS',
  //   icon: 'store',
  //   routerLink: 'receivers',

  //   permissionEntity: PermissionEntity.RECIPIENTS,
  //   permission: Permission.List,
  // },
];

const coreBankingMenus: NavigationOption[] = [
  {
    title: 'NAVBAR.OPTIONS.STATEMENTS',
    icon: 'local_atm',
    routerLink: 'banking/statements',

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.ListEntriesRemote,
  },
  {
    title: 'NAVBAR.OPTIONS.CLOSING',
    icon: 'insert_chart',
    routerLink: 'banking/balance-by-date',

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.GetRemoteBalance,
  },
]

const exportMenus: NavigationOption[] = [
    {
      title: 'NAVBAR.OPTIONS.DATA_EXPORT.EXPORT_TRANSACTIONS',
      icon: 'summarize',
      routerLink: 'export-data/transactions',
      permissionEntity: PermissionEntity.DATA_EXPORT,
      permission: Permission.List,
    },
    !isPartner &&{
      title: 'NAVBAR.OPTIONS.DATA_EXPORT.EXPORT_STATEMENTS',
      icon: 'summarize',
      routerLink: 'export-data/statements',

      permissionEntity: PermissionEntity.DATA_EXPORT,
      permission: Permission.List,
    },
    // {
    //   title: 'NAVBAR.OPTIONS.DATA_EXPORT.EXPORT_BOOK',
    //   icon: 'summarize',
    //   routerLink: 'export-data/book',

    //   permissionEntity: PermissionEntity.DATA_EXPORT,
    //   permission: Permission.List,
    // },
    // {
    //   title: 'NAVBAR.OPTIONS.DATA_EXPORT.EXPORT_ENTRIES',
    //   icon: 'summarize',
    //   routerLink: 'export-data/entries',

    //   permissionEntity: PermissionEntity.DATA_EXPORT,
    //   permission: Permission.List,
    // },
]

const managerOptions: NavigationOption[] = [
  isPartner && {
    title: 'NAVBAR.OPTIONS.INTEGRATION.WEBHOOKS',
    icon: 'send',
    routerLink: 'integration/webhooks',

    permissionEntity: PermissionEntity.API_KEYS,
    permission: Permission.List,
  },
  {
    title: 'NAVBAR.OPTIONS.INTEGRATION.ACCESS_KEYS',
    icon: 'key',
    routerLink: 'integration/access-keys',

    permissionEntity: PermissionEntity.API_KEYS,
    permission: Permission.List,
  },
  {
    title: 'NAVBAR.OPTIONS.OPERATIONAL.BANKING',
    icon: 'account_balance',
    routerLink: 'operational',

    permissionEntity: PermissionEntity.CONTROL_PANEL,
    permission: Permission.GetCashInDocument,
  },
  {
    title: 'NAVBAR.OPTIONS.CONFIG.POLICY',
    icon: 'security',
    routerLink: 'configuration/security',

    permissionEntity: PermissionEntity.POLICY_RULES,
    permission: Permission.List,
  },
  {
    title: 'NAVBAR.OPTIONS.QUERIES',
    icon: 'search',
    routerLink: 'queries',

    permissionEntity: PermissionEntity.PARTICIPANTS,
    permission: Permission.Check,
  },
  {
    title: 'NAVBAR.OPTIONS.CONFIG.SYSTEM_LOGS',
    icon: 'person_search',
    routerLink: 'cloud',

    permissionEntity: PermissionEntity.CLOUD_LOGS,
    permission: Permission.GetLogs,
  },
  {
    title: 'NAVBAR.OPTIONS.CONFIG.AUDIT',
    icon: 'person_search',
    routerLink: 'configuration/audit',

    permissionEntity: PermissionEntity.AUDIT_EVENTS,
    permission: Permission.List,
  },
];

export const lateralNavbarOptionsIp: NavigationOption[] = [
  {
    title: 'Home',
    icon: 'home',
    routerLink: 'home',

    permissionEntity: PermissionEntity.ANALYTICS,
    permission: Permission.AccountMetrics,
  },
  {
    title: 'NAVBAR.OPTIONS.TRANSACTIONS',
    icon: 'attach_money',
    routerLink: 'transactions/list',

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.ListTransactions,
  },
  {
    title: 'NAVBAR.OPTIONS.CORE_BANKING',
    icon: 'account_balance',
    menus: coreBankingMenus,
    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.ListEntriesRemote
  },
  {
    title: 'NAVBAR.OPTIONS.INVOICES',
    icon: 'request_quote',
    routerLink: 'invoices',
    permissionEntity: PermissionEntity.INVOICES,
    permission: Permission.List,
  },
  {
    title: 'NAVBAR.OPTIONS.DATA_EXPORT.TITLE',
    icon: 'swap_vert',
    menus: exportMenus,
    permissionEntity: PermissionEntity.DATA_EXPORT,
    permission: Permission.List,
  },
  {
    title: 'NAVBAR.LATERAL.ENTITIES',
    icon: 'linked_services',
    menus: entitiesOptions,
    roles: Roles.MENU_ROUTES.ENTITIES,
  },
  {
    title: 'NAVBAR.LATERAL.ADMIN',
    icon: 'settings',
    menus: managerOptions,
    roles: Roles.MENU_ROUTES.CONFIGURATIONS,
  },
];
