<mjx-navigator [hide]="hideNavbar">
  <router-outlet class="router-outlet">
    <div *ngIf="isIp && routerOutletLoading" class="router-outlet-loading">
      <svg
        width="66"
        height="100"
        viewBox="0 0 66 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        [ngStyle]="{ marginLeft: navbarVisibility === 'visible' && screenOffsetWidth > 700 ? 250 : 0 }"
      >
        <g id="Symbol PB">
          <path
            id="Down"
            d="M47.1712 38.7017L44.812 38.6858L32.4219 49.7522L5.84071 74.1346C3.98184 75.8416 2.69437 77.9392 2 80.1959V88.333C2.57864 90.2135 3.57679 92.0001 5.00169 93.5479C10.1299 99.139 18.8167 99.5152 24.4078 94.387L59.1188 62.5546C60.7897 61.0212 61.9903 59.1768 62.7209 57.1878L62.757 57.0865C64.4279 52.4357 63.5237 47.0399 59.9506 43.1413C57.2383 40.1903 53.535 38.693 49.8245 38.693C49.7666 38.693 49.7015 38.693 49.6436 38.693"
            stroke="#31BAB2"
            stroke-width="4"
          />
          <path
            id="Up"
            d="M8.60371 2C8.01061 2 7.4609 2.0434 6.96182 2.13019C6.92566 2.13019 6.88226 2.14466 6.8461 2.15189C4.0397 2.66543 2.66543 4.40858 2 6.02877V80.297C3.32364 76.0296 6.25299 73.7367 6.25299 73.7367C6.25299 73.7367 55.6108 29.0369 57.7952 26.8525C62.258 22.3898 65.2452 16.9216 62.5617 9.90565C59.5745 2.10126 51.517 2 51.517 2H8.60371Z"
            stroke="#31BAB2"
            stroke-width="4"
          />
        </g>
      </svg>
    </div>
  </router-outlet>
  <div class="footer" (click)="openDialog()">
    {{ getFooter() }}
  </div>
</mjx-navigator>
