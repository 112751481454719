import {Injectable, NgZone} from "@angular/core";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {ChangeEnablingMfa, ClearSession, EnableMfa, GetCurrentUser, SetLoggedUser, SetToken} from "./session.actions";
import {SessionService} from "../../services/session.service";
import {CurrentUser} from "../../models/user.model";
import {finalize, tap} from "rxjs";
import { AccessRolesEnum } from 'src/app/shared/enums/access-roles.enum';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';
import { Router } from '@angular/router';
import {RefreshTokenService} from "../../services/refresh-token.service";
import {isIp, isPartner} from "../../../shared/utils/get-context";
import {mapPermissionsToEntity, Permission, PermissionEntity, UserPermissions} from "../../models/permission.model";
import { permissionRoutesSet } from "src/app/shared/utils/maps/permission-route.map";
import { permissionRoutesIpSet } from "src/app/shared/utils/maps/permission-route-ip.map";

class SessionStateModel {
  token: string;
  currentUser: CurrentUser;
  loadingUser: boolean;
  enablingMfa: boolean;
  username: string;
  permissions: UserPermissions
}

const defaultSessionState: SessionStateModel = {
  currentUser: null,
  token: null,
  loadingUser: false,
  enablingMfa: false,
  username: null,
  permissions: null
}

@State<SessionStateModel>({
  name: 'session',
  defaults: defaultSessionState
})
@Injectable()
export class SessionState {
  constructor(
    private sessionService: SessionService,
    private ngZone: NgZone,
    private router: Router,
    private refreshTokenService: RefreshTokenService
  ) {}

  @Selector()
  static getCurrentUser(state: Partial<SessionStateModel>) {
    return state.currentUser;
  }

  @Selector()
  static getUsername(state: Partial<SessionStateModel>) {
    return state.username;
  }

  @Selector()
  static getToken(state: Partial<SessionStateModel>) {
    return state.token;
  }

  @Selector()
  static getLoadingUserState(state: Partial<SessionStateModel>) {
    return state.loadingUser;
  }

  @Selector()
  static userRoles(state: Partial<SessionStateModel>) {
    return state.currentUser?.roles;
  }

  @Selector()
  static userPermissions(state: Partial<SessionStateModel>) {
    return state.permissions;
  }

  @Selector()
  static enablingMfa(state: Partial<SessionStateModel>) {
    return state.enablingMfa;
  }

  @Action(GetCurrentUser)
  getCurrentUser(
    stateCtx: StateContext<SessionStateModel>,
    action: GetCurrentUser
  ) {
    stateCtx.patchState({
      loadingUser: true
    });

    return this.sessionService.getCurrentUser()
      .pipe(
        tap((res) => {
          if (!isPartner) {
            this.refreshTokenService.startListen();
          }

          if (res.data) {
            const currentUser = this.sessionService.getUser();

            stateCtx.patchState({
              permissions: mapPermissionsToEntity(res.data?.privileges),
              currentUser: { ...res.data, cid: currentUser?.cid }
            });

            const usersPermission = res.data?.privileges
            let homeRoute = `${RoutesEnum.Home}`;
            const findPermission = isIp
            ?  Array.from(permissionRoutesIpSet).find(entry => usersPermission?.includes(entry.permission))
            : Array.from(permissionRoutesSet).find(entry => usersPermission?.includes(entry.permission))


            if(findPermission) {
              homeRoute = findPermission.route
            } else {
              homeRoute = "unauthorized"
            }

            if (this.router.url === `/${RoutesEnum.Login}`) {
              if( action?.isFirstAccess ) {
                this.ngZone.run(() => this.router.navigateByUrl(`/welcome`))
              } else {
                this.ngZone.run(() => this.router.navigateByUrl(`/${homeRoute}`))
              }
            }

            // this.ngZone.run(() => this.router.navigateByUrl(`/${homeRoute}`))
          }
        }),
        finalize(() => {
          stateCtx.patchState({
            loadingUser: false
          })
        })
      );
  }

  @Action(SetLoggedUser)
  setLoggedUser(
    stateCtx: StateContext<SessionStateModel>,
    actions: SetLoggedUser
  ) {
    stateCtx.patchState({
      token: actions.token,
      currentUser: null
    });
  }

  @Action(SetToken)
  setToken(
    stateCtx: StateContext<SessionStateModel>,
    actions: SetToken
  ) {
    stateCtx.patchState({
      token: actions.token
    });
  }

  @Action(ClearSession)
  clearSession(
    stateCtx: StateContext<SessionStateModel>
  ) {
    this.refreshTokenService.stopListen();
    stateCtx.setState(defaultSessionState);
  }

  @Action(EnableMfa)
  enableMfa(
    stateCtx: StateContext<SessionStateModel>,
    actions: EnableMfa
  ) {
    stateCtx.patchState({ enablingMfa: actions.enable, username: actions.username })
  }

  @Action(ChangeEnablingMfa)
  changeEnablingMfa(
    stateCtx: StateContext<SessionStateModel>,
    actions: ChangeEnablingMfa
  ) {
    stateCtx.patchState({ enablingMfa: actions.state })
  }
}
