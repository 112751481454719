export enum Permission {
  Create = 'Create',
  CreateMembership = 'CreateMembership',
  CreateVirtualTransfer = 'CreateVirtualTransfer',
  DeleteMembership = 'DeleteMembership',
  Get = 'Get',
  GetBalance = 'GetBalance',
  GetConfig = 'GetConfig',
  GetOverview = 'GetOverview',
  GetPreferences = 'GetPreferences',
  GetReportBookCheck = 'GetReportBookCheck',
  GetReportOperationalSummary = 'GetReportOperationalSummary',
  GetReportTransactionsSummary = 'GetReportTransactionsSummary',
  GetTransaction = 'GetTransaction',
  InvokeBankSync = 'InvokeBankSync',
  InvokeTransactionWebhook = 'InvokeTransactionWebhook',
  List = 'List',
  ListEntries = 'ListEntries',
  ListForUser = 'ListForUser',
  ListMembership = 'ListMembership',
  ListTransactions = 'ListTransactions',
  UpdateConfig = 'UpdateConfig',
  UpdateName = 'UpdateName',
  UpdatePreferences = 'UpdatePreferences',
  UpdateState = 'UpdateState',
  UpdateVirtualTransfer = 'UpdateVirtualTransfer',
  AccountMetrics = 'AccountMetrics',
  HeatMap = 'HeatMap',
  Review = 'Review',
  MFAAppAuthConfirm = 'MFAAppAuthConfirm',
  MFAAppAuthSetup = 'MFAAppAuthSetup',
  MFAAppAuthReset = 'MFAAppAuthReset',
  MFAValidate = 'MFAValidate',
  RefreshToken = 'RefreshToken',
  WhoAmI = 'WhoAmI',
  CreateCustomerPlan = 'CreateCustomerPlan',
  DeleteCustomerPlan = 'DeleteCustomerPlan',
  UpdateCustomerPlan = 'UpdateCustomerPlan',
  UpdateStandard = 'UpdateStandard',
  GetLogs = 'GetLogs',
  GetCashInDocument = 'GetCashInDocument',
  GetCashOutDocument = 'GetCashOutDocument',
  PixKeyCheck = 'PixKeyCheck',
  RequestPIXKeyReset = 'RequestPIXKeyReset',
  RequestRefund = 'RequestRefund',
  CreateSite = 'CreateSite',
  DeleteSite = 'DeleteSite',
  ListSites = 'ListSites',
  UpdateSite = 'UpdateSite',
  GetDownloadUrl = 'GetDownloadUrl',
  UpdatePartial = 'UpdatePartial',
  Upload = 'Upload',
  Check = 'Check',
  Onboard = 'Onboard',
  ChangeStatus = 'ChangeStatus',
  ListCnaes = 'ListCnaes',
  AddCNAEs = 'AddCNAEs',
  ApplyBankingRoutingProfile = 'ApplyBankingRoutingProfile',
  CreateBank = 'CreateBank',
  CreateBankingRoutingProfile = 'CreateBankingRoutingProfile',
  DeleteBankingRoutingProfile = 'DeleteBankingRoutingProfile',
  DeleteCNAEs = 'DeleteCNAEs',
  GetBankingConfig = 'GetBankingConfig',
  GetBankingRouting = 'GetBankingRouting',
  GetBanks = 'GetBanks',
  GetCNAEs = 'GetCNAEs',
  ListBankingRoutingProfiles = 'ListBankingRoutingProfiles',
  RemoveBank = 'RemoveBank',
  ReplaceCNAEsList = 'ReplaceCNAEsList',
  UpdateBank = 'UpdateBank',
  UpdateBankingRouting = 'UpdateBankingRouting',
  UpdateBankingRoutingProfile = 'UpdateBankingRoutingProfile',
  UpdateCNAEs = 'UpdateCNAEs',
  Execute = 'Execute',
  Associate = 'Associate',
  ListAssociation = 'ListAssociation',
  ListPrivileges = 'ListPrivileges',
  CreatePartnerUser = 'CreatePartnerUser',
  MFA = 'MFA',
  PasswordChange = 'PasswordChange',
  PasswordReset = 'PasswordReset',
  Update = 'Update',
  Menu = 'Menu',
  Revoke = 'Revoke',
  Delete = 'Delete',
  Cancel = 'Cancel',
  DeleteBankAccounts = 'DeleteBankAccounts',
  MFAAppAuthRevoke = 'MFAAppAuthRevoke',
  PasswordPasswordResetConfirm = 'PasswordPasswordResetConfirm',
  GetBalanceHistory = 'GetBalanceHistory',
  CreateVirtualTransferBetweenPartners = 'CreateVirtualTransferBetweenPartners',
  GetBankAccounts = 'GetBankAccounts',
  CreateBankAccounts = 'CreateBankAccounts',
  CreateCNAEs = 'CreateCNAEs',
  Sync = 'Sync',
  Transactions = 'Transactions',
  GetTransactions = 'GetTransactions',
  CreateCnaes = 'CreateCnaes',
  DeleteCnaes = 'DeleteCnaes',
  Download = 'Download',
  Home = 'Home',
  Heatmap = 'Heatmap',
  EntitiesUsers = 'EntitiesUsers',
  Partners = 'Partners',
  Banks = 'Banks',
  ApiKeys = 'ApiKeys',
  PolicyRules = 'PolicyRules',
  CloudLogs = 'CloudLogs',
  Statement = 'Statement',
  DataExport = 'DataExport',
  Accounts = 'Accounts',
  Roles = 'Roles',
  Recipients = 'Recipients',
  BankingOperations = 'BankingOperations',
  CpfChecker = 'CpfChecker',
  AuditLogs = 'AuditLogs',
  ListBrands = 'ListBrands',
  GetRemoteBalance = 'GetRemoteBalance',
  ListEntriesRemote = 'ListEntriesRemote',
  GetEntryRemote = 'GetEntryRemote',
  CreateTransferBetweenAccounts = 'CreateTransferBetweenAccounts',
  CheckKeyCounterparty = 'CheckKeyCounterparty',
  Payment = 'Payment',
  GetInvoice = 'GetInvoice',
  UpdateStatus = 'UpdateStatus',
  Invoices = 'Invoices',
  Calculate = 'Calculate',
  APICashIn = 'APICashIn',
  APICashOut = 'APICashOut',
  APITransfer = 'APITransfer',
  APIList = 'APIList',
  APIGet = 'APIGet',
  Closing = 'Closing'
}

export enum PermissionEntity {
  ACCOUNTS = 'Accounts',
  ANALYTICS = 'Analytics',
  API_KEYS = 'ApiKeys',
  AUDIT_EVENTS = 'AuditEvents',
  AUTH = 'Auth',
  BILLING_PLAN = 'BillingPlan',
  CLOUD_LOGS = 'CloudLogs',
  CONTROL_PANEL = 'ControlPanel',
  CUSTOMER_BROKERS = 'CustomerBrokers',
  CUSTOMERS = 'Customers',
  DATA_EXPORT = 'DataExport',
  DOCUMENTS = 'Documents',
  LICENSES = 'Licenses',
  MERCHANTS = 'Merchants',
  PARTICIPANTS = 'Participants',
  PARTNERS_ONBOARDING = 'PartnersOnboarding',
  POLICY_RULES = 'PolicyRules',
  RECIPIENTS = 'Recipients',
  SYSTEM_CONFIG = 'SystemConfig',
  TRANSACTION_REQUESTS = 'TransactionRequests',
  USER_ROLES = 'UserRoles',
  USERS = 'Users',
  TRANSACTIONS = 'Transactions',
  COMPANIES = 'Companies',
  ABACUS = 'Abacus',
  INVOICES = 'Invoices',
  PIX = 'Pix',
  BANK = 'Bank',
}

export function mapPermissionsToEntity(permissions: string[]): UserPermissions {
  return permissions.reduce((acc, permission) => {
    const [entity, action] = permission.split('.');
    if (!acc[entity]) {
      acc[entity] = [];
    }
    acc[entity].push(action);
    return acc;
  }, {});
}

export type UserPermissions = Partial<{ [key in PermissionEntity]: Permission[] | string[] }>;
