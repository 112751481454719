import { Permission } from '../../../core/models/permission.model';

export const permissionsTranslateKeysMap = {
  [Permission.Create]: 'PERMISSIONS.NAMES.Create',
  [Permission.CreateMembership]: 'PERMISSIONS.NAMES.CreateMembership',
  [Permission.CreateVirtualTransfer]: 'PERMISSIONS.NAMES.CreateVirtualTransfer',
  [Permission.DeleteMembership]: 'PERMISSIONS.NAMES.DeleteMembership',
  [Permission.Get]: 'PERMISSIONS.NAMES.Get',
  [Permission.GetBalance]: 'PERMISSIONS.NAMES.GetBalance',
  [Permission.GetConfig]: 'PERMISSIONS.NAMES.GetConfig',
  [Permission.GetOverview]: 'PERMISSIONS.NAMES.GetOverview',
  [Permission.GetPreferences]: 'PERMISSIONS.NAMES.GetPreferences',
  [Permission.GetReportBookCheck]: 'PERMISSIONS.NAMES.GetReportBookCheck',
  [Permission.GetReportOperationalSummary]: 'PERMISSIONS.NAMES.GetReportOperationalSummary',
  [Permission.GetReportTransactionsSummary]: 'PERMISSIONS.NAMES.GetReportTransactionsSummary',
  [Permission.GetTransaction]: 'PERMISSIONS.NAMES.GetTransaction',
  [Permission.InvokeBankSync]: 'PERMISSIONS.NAMES.InvokeBankSync',
  [Permission.InvokeTransactionWebhook]: 'PERMISSIONS.NAMES.InvokeTransactionWebhook',
  [Permission.List]: 'PERMISSIONS.NAMES.List',
  [Permission.ListEntries]: 'PERMISSIONS.NAMES.ListEntries',
  [Permission.ListForUser]: 'PERMISSIONS.NAMES.ListForUser',
  [Permission.ListMembership]: 'PERMISSIONS.NAMES.ListMembership',
  [Permission.CreateTransferBetweenAccounts]: 'PERMISSIONS.NAMES.CreateTransferBetweenAccounts',
  [Permission.ListTransactions]: 'PERMISSIONS.NAMES.ListTransactions',
  [Permission.UpdateConfig]: 'PERMISSIONS.NAMES.UpdateConfig',
  [Permission.UpdateName]: 'PERMISSIONS.NAMES.UpdateName',
  [Permission.UpdatePreferences]: 'PERMISSIONS.NAMES.UpdatePreferences',
  [Permission.UpdateState]: 'PERMISSIONS.NAMES.UpdateState',
  [Permission.UpdateVirtualTransfer]: 'PERMISSIONS.NAMES.UpdateVirtualTransfer',
  [Permission.AccountMetrics]: 'PERMISSIONS.NAMES.AccountMetrics',
  [Permission.HeatMap]: 'PERMISSIONS.NAMES.HeatMap',
  [Permission.Review]: 'PERMISSIONS.NAMES.Review',
  [Permission.MFAAppAuthConfirm]: 'PERMISSIONS.NAMES.MFAAppAuthConfirm',
  [Permission.MFAAppAuthReset]: 'PERMISSIONS.NAMES.MFAAppAuthReset',
  [Permission.MFAAppAuthSetup]: 'PERMISSIONS.NAMES.MFAAppAuthSetup',
  [Permission.MFAValidate]: 'PERMISSIONS.NAMES.MFAValidate',
  [Permission.RefreshToken]: 'PERMISSIONS.NAMES.RefreshToken',
  [Permission.WhoAmI]: 'PERMISSIONS.NAMES.WhoAmI',
  [Permission.CreateCustomerPlan]: 'PERMISSIONS.NAMES.CreateCustomerPlan',
  [Permission.DeleteCustomerPlan]: 'PERMISSIONS.NAMES.DeleteCustomerPlan',
  [Permission.UpdateCustomerPlan]: 'PERMISSIONS.NAMES.UpdateCustomerPlan',
  [Permission.UpdateStandard]: 'PERMISSIONS.NAMES.UpdateStandard',
  [Permission.GetLogs]: 'PERMISSIONS.NAMES.GetLogs',
  [Permission.GetCashInDocument]: 'PERMISSIONS.NAMES.GetCashInDocument',
  [Permission.GetCashOutDocument]: 'PERMISSIONS.NAMES.GetCashOutDocument',
  [Permission.PixKeyCheck]: 'PERMISSIONS.NAMES.PixKeyCheck',
  [Permission.RequestPIXKeyReset]: 'PERMISSIONS.NAMES.RequestPIXKeyReset',
  [Permission.RequestRefund]: 'PERMISSIONS.NAMES.RequestRefund',
  [Permission.CreateSite]: 'PERMISSIONS.NAMES.CreateSite',
  [Permission.DeleteSite]: 'PERMISSIONS.NAMES.DeleteSite',
  [Permission.ListSites]: 'PERMISSIONS.NAMES.ListSites',
  [Permission.UpdateSite]: 'PERMISSIONS.NAMES.UpdateSite',
  [Permission.GetDownloadUrl]: 'PERMISSIONS.NAMES.GetDownloadUrl',
  [Permission.UpdatePartial]: 'PERMISSIONS.NAMES.UpdatePartial',
  [Permission.Upload]: 'PERMISSIONS.NAMES.Upload',
  [Permission.Check]: 'PERMISSIONS.NAMES.Check',
  [Permission.Onboard]: 'PERMISSIONS.NAMES.Onboard',
  [Permission.ChangeStatus]: 'PERMISSIONS.NAMES.ChangeStatus',
  [Permission.ListCnaes]: 'PERMISSIONS.NAMES.ListCnaes',
  [Permission.AddCNAEs]: 'PERMISSIONS.NAMES.AddCNAEs',
  [Permission.ApplyBankingRoutingProfile]: 'PERMISSIONS.NAMES.ApplyBankingRoutingProfile',
  [Permission.CreateBank]: 'PERMISSIONS.NAMES.CreateBank',
  [Permission.CreateBankingRoutingProfile]: 'PERMISSIONS.NAMES.CreateBankingRoutingProfile',
  [Permission.DeleteBankingRoutingProfile]: 'PERMISSIONS.NAMES.DeleteBankingRoutingProfile',
  [Permission.DeleteCNAEs]: 'PERMISSIONS.NAMES.DeleteCNAEs',
  [Permission.GetBankingConfig]: 'PERMISSIONS.NAMES.GetBankingConfig',
  [Permission.GetBankingRouting]: 'PERMISSIONS.NAMES.GetBankingRouting',
  [Permission.GetBanks]: 'PERMISSIONS.NAMES.GetBanks',
  [Permission.GetCNAEs]: 'PERMISSIONS.NAMES.GetCNAEs',
  [Permission.ListBankingRoutingProfiles]: 'PERMISSIONS.NAMES.ListBankingRoutingProfiles',
  [Permission.RemoveBank]: 'PERMISSIONS.NAMES.RemoveBank',
  [Permission.ReplaceCNAEsList]: 'PERMISSIONS.NAMES.ReplaceCNAEsList',
  [Permission.UpdateBank]: 'PERMISSIONS.NAMES.UpdateBank',
  [Permission.UpdateBankingRouting]: 'PERMISSIONS.NAMES.UpdateBankingRouting',
  [Permission.UpdateBankingRoutingProfile]: 'PERMISSIONS.NAMES.UpdateBankingRoutingProfile',
  [Permission.UpdateCNAEs]: 'PERMISSIONS.NAMES.UpdateCNAEs',
  [Permission.Execute]: 'PERMISSIONS.NAMES.Execute',
  [Permission.Associate]: 'PERMISSIONS.NAMES.Associate',
  [Permission.ListAssociation]: 'PERMISSIONS.NAMES.ListAssociation',
  [Permission.ListPrivileges]: 'PERMISSIONS.NAMES.ListPrivileges',
  [Permission.CreatePartnerUser]: 'PERMISSIONS.NAMES.CreatePartnerUser',
  [Permission.MFA]: 'PERMISSIONS.NAMES.MFA',
  [Permission.PasswordChange]: 'PERMISSIONS.NAMES.PasswordChange',
  [Permission.PasswordReset]: 'PERMISSIONS.NAMES.PasswordReset',
  [Permission.Update]: 'PERMISSIONS.NAMES.Update',
  [Permission.Menu]: 'PERMISSIONS.NAMES.Menu',
  [Permission.MFAAppAuthRevoke]: 'PERMISSIONS.NAMES.MFAAppAuthRevoke',
  [Permission.Revoke]: 'PERMISSIONS.NAMES.Revoke',
  [Permission.PasswordPasswordResetConfirm]: 'PERMISSIONS.NAMES.PasswordPasswordResetConfirm',
  [Permission.GetBalanceHistory]: 'PERMISSIONS.NAMES.GetBalanceHistory',
  [Permission.CreateVirtualTransferBetweenPartners]:
    'PERMISSIONS.NAMES.CreateVirtualTransferBetweenPartners',
  [Permission.Delete]: 'PERMISSIONS.NAMES.Delete',
  [Permission.GetBankAccounts]: 'PERMISSIONS.NAMES.GetBankAccounts',
  [Permission.CreateBankAccounts]: 'PERMISSIONS.NAMES.CreateBankAccounts',
  [Permission.DeleteBankAccounts]: 'PERMISSIONS.NAMES.DeleteBankAccounts',
  [Permission.CreateCNAEs]: 'PERMISSIONS.NAMES.CreateCNAEs',
  [Permission.Sync]: 'PERMISSIONS.NAMES.Sync',
  [Permission.Transactions]: 'PERMISSIONS.NAMES.Transactions',
  [Permission.GetTransactions]: 'PERMISSIONS.NAMES.GetTransactions',
  [Permission.CreateCnaes]: 'PERMISSIONS.NAMES.CreateCnaes',
  [Permission.DeleteCnaes]: 'PERMISSIONS.NAMES.DeleteCnaes',
  [Permission.Home]: 'PERMISSIONS.NAMES.Home',
  [Permission.Heatmap]: 'PERMISSIONS.NAMES.Heatmap',
  [Permission.Statement]: 'PERMISSIONS.NAMES.Statement',
  [Permission.Closing]: 'PERMISSIONS.NAMES.Closing',
  [Permission.EntitiesUsers]: 'PERMISSIONS.NAMES.EntitiesUsers',
  [Permission.DataExport]: 'PERMISSIONS.NAMES.DataExport',
  [Permission.Partners]: 'PERMISSIONS.NAMES.Partners',
  [Permission.Banks]: 'PERMISSIONS.NAMES.Banks',
  [Permission.Roles]: 'PERMISSIONS.NAMES.Roles',
  [Permission.Accounts]: 'PERMISSIONS.NAMES.Accounts',
  [Permission.Recipients]: 'PERMISSIONS.NAMES.Recipients',
  [Permission.ApiKeys]: 'PERMISSIONS.NAMES.ApiKeys',
  [Permission.BankingOperations]: 'PERMISSIONS.NAMES.BankingOperations',
  [Permission.PolicyRules]: 'PERMISSIONS.NAMES.PolicyRules',
  [Permission.CpfChecker]: 'PERMISSIONS.NAMES.CpfChecker',
  [Permission.CloudLogs]: 'PERMISSIONS.NAMES.CloudLogs',
  [Permission.AuditLogs]: 'PERMISSIONS.NAMES.AuditLogs',
  [Permission.GetEntryRemote]: 'PERMISSIONS.NAMES.GetEntryRemote',
  [Permission.ListEntriesRemote]: 'PERMISSIONS.NAMES.ListEntriesRemote',
  [Permission.ListBrands]: 'PERMISSIONS.NAMES.ListBrands',
  [Permission.GetRemoteBalance]: 'PERMISSIONS.NAMES.GetRemoteBalance',
  [Permission.GetInvoice]: 'PERMISSIONS.NAMES.GetInvoice',
  [Permission.UpdateStatus]: 'PERMISSIONS.NAMES.UpdateStatus',
  [Permission.Invoices]: 'PERMISSIONS.NAMES.Invoices',
  [Permission.Calculate]: 'PERMISSIONS.NAMES.Calculate',
  [Permission.CheckKeyCounterparty]: 'PERMISSIONS.NAMES.CheckKeyCounterparty',
  [Permission.Payment]: 'PERMISSIONS.NAMES.Payment',
  [Permission.APICashIn]: 'PERMISSIONS.NAMES.APICashIn',
  [Permission.APICashOut]: 'PERMISSIONS.NAMES.APICashOut',
  [Permission.APITransfer]: 'PERMISSIONS.NAMES.APITransfer',
  [Permission.APIList]: 'PERMISSIONS.NAMES.APIList',
  [Permission.APIGet]: 'PERMISSIONS.NAMES.APIGet',
};
