import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {environment} from "../../../../environments/environment";
import {routes} from "../../../../environments/vendor/routes/routes";
import { PartnerBillingPlan } from '../models/partner-billing-plan.model';
import { PaginationModel } from 'src/app/shared/models/pagination.model';
import { CommonResponse } from 'src/app/shared/models/response.model';

const API = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class PartnersBillingPlansService {

  constructor(
    private http: HttpClient,
  ) { }

  getBillingPlans(partnerId: number) {
    const url = `${API}/${routes?.billingPlans}?customerId=${partnerId}`;

    return this.http.get<CommonResponse<PaginationModel<PartnerBillingPlan>>>(url);
  }

  createBillingPlan(billingPlan: PartnerBillingPlan) {
    const url = `${API}/${routes?.billingPlans}/customers/${billingPlan.refCustomer}`;

    const {
      scheduleStartsAt,
      scheduleEndsAt,
      pixCashInCost,
      pixCashInRate,
      pixCashInMinLimit,
      pixCashInMaxLimit,
      pixCashOutCost,
      pixCashOutRate,
      pixCashOutMinLimit,
      pixCashOutMaxLimit,
      pixRefundCost,
      pixRefundRate,
      pixRefundMinLimit,
      pixRefundMaxLimit,
    } = billingPlan;

    return this.http
      .post<CommonResponse<PartnerBillingPlan>>(
        url,
        {
          scheduleStartsAt,
          scheduleEndsAt,
          pixCashInCost,
          pixCashInRate,
          pixCashInMinLimit,
          pixCashInMaxLimit,
          pixCashOutCost,
          pixCashOutRate,
          pixCashOutMinLimit,
          pixCashOutMaxLimit,
          pixRefundCost,
          pixRefundRate,
          pixRefundMinLimit,
          pixRefundMaxLimit,
        });
  }

  updateBillingPlan(billingPlan: PartnerBillingPlan) {
    const url =
      `${API}/${routes?.billingPlans}/customers/${billingPlan.refCustomer}`;

    const {
      id,
      scheduleStartsAt,
      scheduleEndsAt,
      pixCashInCost,
      pixCashInRate,
      pixCashInMinLimit,
      pixCashInMaxLimit,
      pixCashOutCost,
      pixCashOutRate,
      pixCashOutMinLimit,
      pixCashOutMaxLimit,
      pixRefundCost,
      pixRefundRate,
      pixRefundMinLimit,
      pixRefundMaxLimit,
    } = billingPlan;

    return this.http
      .patch(
        url,
        {
          id,
          scheduleStartsAt,
          scheduleEndsAt,
          pixCashInCost,
          pixCashInRate,
          pixCashInMinLimit,
          pixCashInMaxLimit,
          pixCashOutCost,
          pixCashOutRate,
          pixCashOutMinLimit,
          pixCashOutMaxLimit,
          pixRefundCost,
          pixRefundRate,
          pixRefundMinLimit,
          pixRefundMaxLimit,
        });
  }

  updateActiveBillingPlan(billingPlan: PartnerBillingPlan) {
    const url = `${API}/${routes?.billingPlans}/standard`;

    const {
      pixCashInCost,
      pixCashInRate,
      pixCashInMinLimit,
      pixCashInMaxLimit,
      pixCashOutCost,
      pixCashOutRate,
      pixCashOutMinLimit,
      pixCashOutMaxLimit,
      pixRefundCost,
      pixRefundRate,
      pixRefundMinLimit,
      pixRefundMaxLimit,
    } = billingPlan;

    const payload = {
      pixCashInCost,
      pixCashInRate,
      pixCashOutCost,
      pixCashOutRate,
      pixRefundCost,
      pixRefundRate,
      ...(pixCashInMinLimit != null && { pixCashInMinLimit }),
      ...(pixCashInMaxLimit != null && { pixCashInMaxLimit }),
      ...(pixCashOutMinLimit != null && { pixCashOutMinLimit }),
      ...(pixCashOutMaxLimit != null && { pixCashOutMaxLimit }),
      ...(pixRefundMinLimit != null && { pixRefundMinLimit }),
      ...(pixRefundMaxLimit != null && { pixRefundMaxLimit }),
    };

    return this.http.patch(url, payload);
  }

  archiveBillingPlan(billingPlan: PartnerBillingPlan) {
    const url =
    `${API}/${routes?.billingPlans}/customers/${billingPlan.refCustomer}`;

    const { id } = billingPlan;

    return this.http
      .delete(
        url, {body: { id }});
  }
}
