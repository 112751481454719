import {ContextRoutesInterface} from "../../../app/shared/interfaces/context-routes.interface";

const ROUTE_CONTEXT = 'corporate';

const routes: ContextRoutesInterface = {
  login: `${ROUTE_CONTEXT}/auth/login`,
  transactions: `${ROUTE_CONTEXT}/accounts/transactions`,
  accounts: `${ROUTE_CONTEXT}/accounts`,
  documents: `${ROUTE_CONTEXT}/documents`,
  billingPlans: `${ROUTE_CONTEXT}/billing-plans`,
  customers: `${ROUTE_CONTEXT}/customers`,
  merchants: `${ROUTE_CONTEXT}/licenses`,
  receivers: `${ROUTE_CONTEXT}/receivers`,
  users: `${ROUTE_CONTEXT}/users`,
  locations: 'system/locations',
  whoAmI: `${ROUTE_CONTEXT}/auth/whoAmI`,
  mfa: `${ROUTE_CONTEXT}/auth/mfa`,
  systemConfig: `${ROUTE_CONTEXT}/system-config`,
  policyRules: `${ROUTE_CONTEXT}/policy-rules`,
  participants: `${ROUTE_CONTEXT}/participants`,
  controlPanel: `${ROUTE_CONTEXT}/control-panel`,
  auditEvents: `${ROUTE_CONTEXT}/audit-events`,
  cloudLogs: `${ROUTE_CONTEXT}/cloud-logs`,
  auth: `${ROUTE_CONTEXT}/auth`,
  exports: `${ROUTE_CONTEXT}/exports`,
  banking: `${ROUTE_CONTEXT}/system-config/banking`,
  cnaes: `${ROUTE_CONTEXT}/system-config/cnaes`,
  security: `${ROUTE_CONTEXT}/security`,
  statements: `${ROUTE_CONTEXT}/accounts`,
  invoices: `${ROUTE_CONTEXT}/invoices`,
}

export {
  ROUTE_CONTEXT,
  routes
};
