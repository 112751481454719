export enum RoutesEnum {
  Home = 'home',
  Login = 'login',
  Reset = 'reset',
  Clients = 'clients',
  Sales = 'sales',
  Transactions = 'transactions',
  Export_transactions = 'export-transactions',
  ExportData  = 'export-data',
  Partners = 'partners',
  Users = 'users',
  Integration = 'integration',
  Merchants = 'merchants',
  Licenses = 'licenses',
  Receivers = 'receivers',
  Entries = 'entries',
  Configuration = 'configuration',
  Accounts = 'accounts',
  First_Access = 'first-access',
  Invoices = 'invoices',
  InvoicesDetails = 'invoices/details'
}
