import { Pipe, PipeTransform } from '@angular/core';
import {EntryAccountModel} from "../../modules/entries/models/entry-account.model";

@Pipe({
  name: 'accountCompleteName'
})
export class AccountCompleteNamePipe implements PipeTransform {

  transform(id: string | number, accounts: EntryAccountModel[], properties: ('id' | 'uid' | 'accountNumber') | ('id' | 'uid' | 'accountNumber')[]): string | number {
    const propsToCheck = Array.isArray(properties) ? properties : [properties];
    for (const property of propsToCheck) {
      const selectedAccount = accounts.find(account => account[property] === id);
      if (selectedAccount){
        return selectedAccount.composedName
      }
    }
    return id;
  }

}
