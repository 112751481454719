import { PermissionEntity } from '../../../core/models/permission.model';

export const permissionsEntitiesTranslateKeysMap = {
  [PermissionEntity.ANALYTICS]: 'PERMISSIONS.ENTITIES.ANALYTICS',
  [PermissionEntity.API_KEYS]: 'PERMISSIONS.ENTITIES.API_KEYS',
  [PermissionEntity.AUDIT_EVENTS]: 'PERMISSIONS.ENTITIES.AUDIT_EVENTS',
  [PermissionEntity.AUTH]: 'PERMISSIONS.ENTITIES.AUTH',
  [PermissionEntity.BILLING_PLAN]: 'PERMISSIONS.ENTITIES.BILLING_PLAN',
  [PermissionEntity.CLOUD_LOGS]: 'PERMISSIONS.ENTITIES.CLOUD_LOGS',
  [PermissionEntity.CONTROL_PANEL]: 'PERMISSIONS.ENTITIES.CONTROL_PANEL',
  [PermissionEntity.CUSTOMER_BROKERS]: 'PERMISSIONS.ENTITIES.CUSTOMER_BROKERS',
  [PermissionEntity.CUSTOMERS]: 'PERMISSIONS.ENTITIES.CUSTOMERS',
  [PermissionEntity.DATA_EXPORT]: 'PERMISSIONS.ENTITIES.DATA_EXPORT',
  [PermissionEntity.DOCUMENTS]: 'PERMISSIONS.ENTITIES.DOCUMENTS',
  [PermissionEntity.LICENSES]: 'PERMISSIONS.ENTITIES.LICENSES',
  [PermissionEntity.MERCHANTS]: 'PERMISSIONS.ENTITIES.MERCHANTS',
  [PermissionEntity.PARTICIPANTS]: 'PERMISSIONS.ENTITIES.PARTICIPANTS',
  [PermissionEntity.PARTNERS_ONBOARDING]: 'PERMISSIONS.ENTITIES.PARTNERS_ONBOARDING',
  [PermissionEntity.POLICY_RULES]: 'PERMISSIONS.ENTITIES.POLICY_RULES',
  [PermissionEntity.RECIPIENTS]: 'PERMISSIONS.ENTITIES.RECIPIENTS',
  [PermissionEntity.SYSTEM_CONFIG]: 'PERMISSIONS.ENTITIES.SYSTEM_CONFIG',
  [PermissionEntity.TRANSACTION_REQUESTS]: 'PERMISSIONS.ENTITIES.TRANSACTION_REQUESTS',
  [PermissionEntity.USER_ROLES]: 'PERMISSIONS.ENTITIES.USER_ROLES',
  [PermissionEntity.USERS]: 'PERMISSIONS.ENTITIES.USERS',
  [PermissionEntity.ACCOUNTS]: 'PERMISSIONS.ENTITIES.ACCOUNTS',
  [PermissionEntity.TRANSACTIONS]: 'PERMISSIONS.ENTITIES.TRANSACTIONS',
  [PermissionEntity.COMPANIES]: 'PERMISSIONS.ENTITIES.COMPANIES',
  [PermissionEntity.ABACUS]: 'PERMISSIONS.ENTITIES.ABACUS',
  [PermissionEntity.INVOICES]: 'PERMISSIONS.ENTITIES.INVOICES',
  [PermissionEntity.BANK]: 'PERMISSIONS.ENTITIES.BANK',
};
